import { defineStore } from "pinia";
import privateRoutes from "@/router/privateRoutes.json";
import { useUserStore } from "./user";

export const useMenuStore = defineStore("menu", {
  state: () => {
    return {
      pages: [],
    };
  },
  getters: {},
  actions: {
    getPages() {
      const userStore = useUserStore();
      let routes = [];
      routes = routes.concat(privateRoutes);
      routes = routes.filter(page => page.visible !== false)
      routes = routes.filter(page => page.meta.role === undefined || (page.meta.role && userStore.hasRole(page.meta.role)))
      this.pages = routes; 
    },
  },
});
