<template>
  <v-app id="app">
    <MessageBox ref="$toast"></MessageBox>
    <core-app-bar />
    <core-nav-bar v-if="userStore.isAuthenticated" />
    <core-view />
    <core-footer />
  </v-app>
</template>

<script>
import CoreNavBar from "@/components/core/NavBar.vue";
import CoreAppBar from "@/components/core/AppBar.vue";
import CoreView from "@/components/core/View.vue";
import CoreFooter from "@/components/core/Footer.vue";
import MessageBox from "@/components/base/MessageBox.vue";
import { useUserStore } from "./stores/user";

export default {
  components: {
    CoreNavBar,
    CoreAppBar,
    CoreView,
    CoreFooter,
    MessageBox,
  },
  setup() {
    const userStore = useUserStore();
    return {
      userStore: userStore,
    };
  },
  methods: {

  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

html {
  overflow-y: unset;
  font-size: 14px;
}

#app {
  font-family: Montserrat, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;

  .v-application--wrap {
    max-height: 100vh;
    height: 100vh;
    min-height: unset;
  }

  .v-application--wrap > * {
    flex-grow: 0;
    flex-shrink: 0;
  }

  #core-view {
    flex-grow: 1;
    flex-shrink: 1;
    min-height: 100vh;
    main {
      display: flex;
      flex-direction: column;

      section.content {
        overflow: auto;
      }
    }
  }

  #core-footer {
    flex-shrink: 0;
    flex-basis: 100%;
  }
}

.content > section {
  margin: 1em;
}

.theme--light.v-icon{
  color: var(--v-primary-base);
}
</style>
