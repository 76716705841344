<template>
  <v-footer id="core-footer" class="white--text gray py-4">
    <v-row>
      <v-col>
        <p class="my-0 font-weight-bold">INCOMAC S.R.L.</p>
        <!-- <p class="my-0">Via G. Ferraris, 5031044 Montebelluna (Tv) - Italia</p>
        <p class="my-0">+39 0423 21646</p>
        <p class="my-0">info@incomac.com - www.incomac.com</p> -->

        <!-- 
        //margin
        #//atk a detsra allineato con www
        #//incomac grassetto 900
        #//another website ATK

        // pagination?
        #//icone Mattia
        //albi x lo scroll
        //emi x il checkuout
          -->
      </v-col>
      <v-col class="mt-auto">
        <p class="font-weight-light copyright min text-right ml-auto mb-0">
          Just another website by <a class="white--text" href="https://www.atklab.com/">ATK+LAB</a> &copy;
          {{ new Date().getFullYear() }}
        </p>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "CoreFooter",
};
</script>

<style lang="scss"></style>
