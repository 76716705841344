<template>
  <v-app-bar id="core-app-bar">
    <router-link style="height: 100%" to="/">
      <img
        src="../../assets/logo_incomac_positivo_no_pay.svg"
        alt="Incomac logo"
        height="100%"
        class="justify-self-center"
      />
    </router-link>
    <v-spacer></v-spacer>
    <v-chip-group
      @change="changeLanguage"
      :value="$i18n.locale"
      active-class="primary"
      class="lang-selector"
    >
      <v-chip
        v-for="lang in languages"
        :key="lang.id"
        :value="lang"
        :data-lang="lang.locale"
        :class="{ 'v-chip--active primary': lang.locale === $i18n.locale}"
        >{{ lang.locale }}</v-chip
      >
    </v-chip-group>
    <v-btn icon href="https://forms.clickup.com/20594338/f/kmfn2-7975/LIJ5R3YHFPZ7TUVHWG" target="_blank">
      <v-icon>mdi-frequently-asked-questions</v-icon>
    </v-btn>
    <v-avatar color="primary" class="white--text" size="42" v-if="isAuthenticated"> {{ acronym }} </v-avatar>
    <v-btn icon class="elevation-0 small" elevation="0" to="/logout">
      <v-icon>
        mdi-logout
      </v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { useUserStore } from "@/stores/user";
import { storeToRefs } from "pinia";

export default {
  name: "CoreAppBar",
  setup() {
    const userStore = useUserStore();
    userStore.getLanguages();
    
    const { languages, getAcronym:acronym,isAuthenticated } = storeToRefs(userStore);
    return {
      languages,
      userStore,
      acronym,
      isAuthenticated
    };
  },
  methods: {
    changeLanguage(language) {
      this.userStore.setLanguage(language);
      this.$i18n.locale = language.locale;
    },
  },
  mounted(){
    this.$i18n.locale = this.userStore.currentLocale;
  }
};
</script>
<style></style>
