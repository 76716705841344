import Vue from "vue";
import VueRouter from "vue-router";
import privateRoutes from "./privateRoutes.json";
import publicRoutes from "./publicRoutes.json";
import { useUserStore } from "@/stores/user";
import pinia from "@/plugins/store";
Vue.use(VueRouter);

let routes = [];

routes = routes.concat(privateRoutes);
routes = routes.concat(publicRoutes);

routes = routes.map((page) => {
  return {
    path: page.url,
    name: page.name,
    meta: page.meta,
    component: () => import(`../views/${page.view}.vue`),
  };
});

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore(pinia);
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (userStore.isAuthenticated) {
      if(to.matched.length === 1){
        const record = to.matched[0];
        if(record.meta.role){
          if(userStore.hasRole(record.meta.role)){
            next();
            return;
          }else{
            next("/");
            return;
          }
        }
      }
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
