<template>
  <v-snackbar v-model="errors.length" color="error" top right>
    <p v-for="(error, idx) of errors" :key="idx">
      {{ error }}
    </p>

    <!-- <template v-slot:action="{ attrs }">
      <v-btn color="blue" text v-bind="attrs" @click="message.show = false">
        Close
      </v-btn>
    </template> -->
  </v-snackbar>
</template>
<script>
import { useErrorStore } from "@/stores/error";
export default {
  name: "MessageBox",
  data() {
    return {
      errorStore: null,
    };
  },
  emit: ["input"],
  computed: {
    errors:{
      get(){
        if(this.errorStore){
          let errors = this.errorStore.errors;
          return errors;
        }else{
          return [];
        }
      }
    }

  },
  mounted(){
    this.errorStore = useErrorStore();
  },
  methods: {
  },
};
</script>
