<template>
    <div id="core-view" class="theme--light v-sheet">
        <router-view />
    </div>
</template>

<script>
  export default {
    name: 'CoreView',
  }
</script>

<style lang="scss">
  
</style>
