<script setup>
import { useMenuStore } from "@/stores/menu";

const menu = useMenuStore();
menu.getPages();
</script>

<template>
  <nav class="dark primary">
    <template v-for="page in menu.pages">
      <router-link :to="page.url" :key="page.url"> {{ $t(page.name) }}</router-link>
    </template>
  </nav>
</template>

<script>
export default {
  name: "CoreNavBar",
  data: ()=>({

  })
};
</script>

<style scoped lang="scss">
nav {
  display: block;
  padding: 1em;
  background-color: var(--v-primary-base);

  a {
    color: var(--v-text-light-base);
    text-decoration: none;
    margin-right: 1em;
    text-transform: uppercase;
  }
}
</style>
