import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/vue-i18n";
import router from "./router";
import { PiniaVuePlugin } from "pinia";
import pinia from "@/plugins/store"
import VueCookies from 'vue-cookies'


Vue.use(VueCookies)

Vue.config.productionTip = false;

Vue.use(PiniaVuePlugin);


const vm = new Vue({
  vuetify,
  pinia,
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

window.vm = vm;
