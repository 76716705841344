import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#1f7cc0',
        anchor: '#1f7cc0',
        secondary: '#1f7cc0',
        accent: '#1f7cc0',
        error: '#b71c1c',
        gray: '#6b6f79',
        "blue-icon": '#0d3b51',
        "text-light": '#fff',
        "status-new": "#ffc800 ",
        "status-saved": "#ffc800 ",
        "status-calculated": "#17f984",
        "status-error": "#ff4e4e",
      },
      dark:{
        primary: '#1f7cc0',
        anchor: '#fff',
        secondary: '#1f7cc0',
        accent: '#1f7cc0',
        error: '#b71c1c',
        "text-light": '#fff',
      }
    },
    options: {
      customProperties: true,
      variations: false
    },
  },
})