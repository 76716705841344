import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "../lang/en";
import it from "../lang/it";
import fr from "../lang/fr";
import es from "../lang/es";
import ru from "../lang/ru";
import de from "../lang/de";

Vue.use(VueI18n);

export default new VueI18n({
  locale: "it",
  fallbackLocale: "it",
  silentTranslationWarn: true,
  allowComposition: true,
  messages: {
    en,
    it,
    fr,
    es,
    ru,
    de,
  },
});
