import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import api from "@/http/api";
import _ from "lodash";

export const useUserStore = defineStore("user", {
  state: () => {
    return {
      profile: useStorage("incomac-profile", null, undefined, {
        serializer: {
          read: (v) => (v ? JSON.parse(v) : null),
          write: (v) => JSON.stringify(v),
        },
      }),
      session: useStorage("incomac-session", null, undefined, {
        serializer: {
          read: (v) => (v ? JSON.parse(v) : null),
          write: (v) => JSON.stringify(v),
        },
      }),
      language: useStorage("incomac-language", null, undefined, {
        serializer: {
          read: (v) => (v ? JSON.parse(v) : null),
          write: (v) => JSON.stringify(v),
        },
      }),
      languages: [],
    };
  },
  getters: {
    getToken: (state) => state.profile?.token || null,
    getRefreshToken: (state) => state.profile?.refreshToken || null,
    isAuthenticated: (state) => state.session !== null,
    getAcronym: (state) => {
      if (!state.profile || !state.profile.nome || !state.profile.cognome) {
        return "  ";
      }

      return (
        state.profile.nome.charAt(0) + state.profile.cognome.charAt(0)
      ).toUpperCase();
    },
    currentLocale: (state) => (state.language ? state.language.locale : "it"),
    canDo: (state) => (functionName) => {
      console.log(state, functionName);
      return;
    },
    hasRole: (state) => (roleName) => {
      if (!state.isAuthenticated) {
        return false;
      }

      roleName = roleName.toUpperCase();
      let result = _.some(state.profile.authorities, {
        authority: `ROLE_${roleName}`,
      });

      return result === true;
    },
    isAdmin: (state) => {
      return state.hasRole("ADMIN");
    },
  },
  actions: {
    login(username, password) {
      return new Promise((resolve, reject) => {
        if (username && password) {
          api
            .post(
              `/login`,
              new URLSearchParams({
                username,
                password,
              }),
              {
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
              }
            )
            .then((response) => {
              const { data } = response;
              this.profile = { ...data };
              this.session = data.token;
              resolve();
              return;
            })
            .catch((error) => {
              reject(error.response.data.msg);
            });
        } else {
          reject("Missing data");
        }
      });
    },
    async logout() {
      this.session = null;
      this.profile = null;
    },

    async getLanguages() {
      return await api
        .get("/language/list")
        .then((languages) => (this.languages = languages.data))
    },

    async getCurrentLanguage() {
      return await api.get("/language/getCurrentLocale").then(() => {
        // this.language = language.data;
        // this.$i18n.locale = this.language.locale;
      });
    },
    async setLanguage(language) {
      this.language = language;
    },
  },
});
