import Vue from "vue";

// Lib imports
import axios from "axios";
import router from "@/router";
import { useUserStore } from "@/stores/user";
import { useErrorStore } from "@/stores/error";

axios.defaults.withCredentials = true;

// axios.defaults.baseURL = 'http://172.27.254.150:9080/incomac-backend';
// axios.defaults.baseURL = "https://incomac.api.atklab.dev/incomac-backend";
// axios.defaults.baseURL = 'http://quote.incomac.com/incomac-backend';
// axios.defaults.baseURL = "http://10.224.0.11:8080/incomac-backend";
axios.defaults.baseURL = "https://incomac.api.atklab.com"; // production
// axios.defaults.baseURL = "https://incomac.api.atklab.dev/"; // staging

var isTokenRefreshing = false;

axios.interceptors.request.use(
  (config) => {
    const userStore = useUserStore();
    if (userStore.isAuthenticated && isTokenRefreshing === false) {
      config.headers = {
        ...config.headers,
        ...{
          Authorization: `Bearer ${userStore.getToken}`,
        },
      };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const errorStore = useErrorStore();
    const originalRequest = error.config;
    if (error.response?.status === 401) {
      console.log({ error });
      console.log({ config: error.config });
      console.log({ responde: error.response });
      console.log({ originalRequest });

      if (error.response.data) {
        const errorData = error.response.data;

        if (errorData.authTokenStatus === "expired") {
          if (isTokenRefreshing === false) {
            isTokenRefreshing = updateTokenPromise();
          }

          return new Promise((resolve) => {
            isTokenRefreshing
              .then(() => {
                isTokenRefreshing = false;
                resolve(axios(originalRequest)); //override Authorization Bearer with the new one
              })
              .catch(() => {
                isTokenRefreshing = false;
              });
          });
        } else {
          errorStore.push(error.response.data.msg);
          return Promise.reject(error);
        }
      }
    }

    errorStore.push(error.message);
    return Promise.reject(error);
  }
);

function updateTokenPromise() {
  const userStore = useUserStore();
  return new Promise((resolve, reject) => {
    if (userStore.isAuthenticated) {
      axios
        .post(
          `/user/refresh`,
          {
            token: `${userStore.getToken}`,
            refreshToken: `${userStore.getRefreshToken}`,
          },
          {}
        )
        .then((response) => {
          const { data } = response;
          userStore.profile = { ...data };  //gestione ROLE???
          userStore.session = data.token;
          resolve();
          return;
        })
        .catch(() => {
          logout();
          reject();
        });
    } else {
      logout();
      reject();
    }
  });
}

function logout() {
  const userStore = useUserStore();
  const errorStore = useErrorStore();
  errorStore.push("SESSIONE SCADUTA");
  userStore.logout();
  router.push("/login");
  return;
}

Vue.prototype.$http = axios;
Vue.prototype.axios = axios;
Vue.prototype.$axios = axios;

export default axios;
