import { defineStore } from "pinia";
export const useErrorStore = defineStore("error", {
  state: () => {
    return {
      errors:[]
    };
  },
  getters: {
  },
  actions: {
    push(newError) {
      this.errors.push(newError);
    },
    clean() {
      this.errors = [];
    },
  },
});
